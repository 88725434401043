<template>
  <div>
    <!-- Filters -->
    <!--
    <list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :extens-options="extensOptions"
      :project-options="projectOptions"
      :profile-options="profileOptions"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
      :isEdit="isEdit"
      :currentData="currentData"
    />

    <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button variant="primary" @click="modal_record_details = true">
                <span class="text-nowrap">{{ lang("t_add") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(project)="data">
          {{
            projects.find((e) => e.internal_name == data.item.project)
              .display_name
          }}
        </template>
        <template #cell(filters)="data">
          <span v-for="(item, i) in data.item.filters" :key="i">{{
            `${
              column_labels[item.column] == undefined
                ? item.column
                : column_labels[item.column]
            }${item.operand}"${item.value}",`
          }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ dataMeta.of }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
     no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      size="lg"
      scrollable
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          style="max-height: 80vh"
        >
          <!-- Project -->
          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="Project"
                rules="required"
              >
                <b-form-group :label="lang('t_project')" label-for="project">
                  <v-select
                    v-model="currentData.project"
                    :options="projects"
                    label="display_name"
                    :reduce="(val) => val.internal_name"
                    @input="get_columns"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_displayName')"
                  label-for="display_name"
                >
                  <b-form-input
                    id="display_name"
                    v-model="currentData.display_name"
                    autofocus
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div>
            <div>
              <h4>{{ lang("t_filters") }}</h4>

              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in currentData.filters"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- Item Name -->
                <b-col md="4" sm="6">
                  <b-form-group
                    :label="lang('t_columnName')"
                    label-for="profile"
                  >
                    <v-select
                      :reduce="(val) => val.internal_name"
                      label="display_name"
                      :options="columns"
                      v-model="item.column"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col md="4" sm="6">
                  <b-form-group
                    :label="lang('t_operator')"
                    label-for="t_operator"
                  >
                    <v-select
                      :options="['=', '!=', '<', '>', '<=', '>=']"
                      v-model="item.operand"
                    />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col md="3" sm="6">
                  <b-form-group :label="lang('t_value')" label-for="t_value">
                    <b-form-input autofocus id="t_value" v-model="item.value" />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col md="1" sm="6">
                  <b-button
                    block
                    variant="outline-danger"
                    class="btn-icon mt-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-col>
                <div>
                  <hr />
                </div>
              </b-row>
            </div>
            <b-button block variant="primary" @click="repeateAgain">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{ lang("t_addFilter") }}</span>
            </b-button>
            <b-button block variant="danger" @click="currentData.filters = []">
              <feather-icon icon="Trash2Icon" class="mr-25" />
              <span>{{ lang("t_clearFilter") }}</span>
            </b-button>
          </div>

          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import storeModule from '../storeModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { heightTransition } from '@core/mixins/ui/transition'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mixins: [heightTransition],
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      isEdit: false,
      currentData: {
        project: '',
        internal_name: '',
        display_name: '',
        filters: [],
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      nextTodoId: 2,
      items: [],
      columns: [],
      records: [],
      trHeight: null,
      projects: [],
      tableColumns: [
        { key: 'display_name', label: globalThis._lang('t_filterName'), sortable: true },
        { key: 'project', label: globalThis._lang('t_projectName'), sortable: true },
        // { key: 'create_date', label: globalThis._lang('t_columnNameCreateDate'), sortable: true },
        { key: 'filters', label: globalThis._lang('t_filter'), sortable: true },
        { key: 'actions', label: globalThis._lang('t_actions'), sortable: false },
      ],
      column_labels: {
        status: "Durum",
        assigned_agent: "Agent",
        attempts: "Temas",
        campaign: "Kampanya",
        finish_code: "Son Sonuç Kodu",
        insert_date: "Arama T.",
        action_date: "Aranacak T.",
        create_date: "Yüklenme T.",
        ex_agent: "Ex Agent",
        assign_date: "Atama T.",
      },
    }
  },
  mounted() {
    this.initTrHeight();
    this.get_records();
    this.get_project();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    trAddHeight(val) {
      // Add height to existing height
      // Usage: Where new element is append or more height is added (e.g. list append)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
      this.trHeight = `${heightValue + Number(val)}px`
    },
    trTrimHeight(val) {
      // Remove height from existing height
      // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(this.trHeight.substring(0, this.trHeight.length - 2))
      this.trHeight = `${heightValue - Number(val)}px`
    },
    trSetHeight(val) {
      // Set height
      // Usage: Mostly for assigning initial value from mounted hook

      /* Assumes:
       - Height is not assigned and what to assign for add/remove operation
       - What to set height at something for odd usage
       - Incoming value is valid number in `Number` or `String`
      */
      if (val === null) this.trHeight = 'auto'
      else this.trHeight = `${Number(val)}px`
    },
    get_records: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsFilter`)
      ).data;
      console.log("SmsFilter", response);
      this.records = response;
    },
    get_columns: async function () {
      this.columns = [];
      console.log("currentData.project", this.currentData.project);
      var response = (
        await this.$http_in.get(`spv/v1/ProjectColumns/${this.currentData.project}_customers`)
      ).data;
      console.log("SmsColumns", response);
      //this.columns = response;
      for (const item of response[0].allkeys) {
        if (['active', '_id', 'cycles', 'TCKN_masked', 'notes', 'phones', 'products', 'campaign', 'sms', 'extra_data', 'daily_attempts', 'update_date', 'assigned_agent2'].includes(item)) {
          continue;
        }
        this.columns.push({
          internal_name: item,
          display_name: this.column_labels[item] == undefined ? item : this.column_labels[item]
        })
      }
    },
    get_project: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/Project`)
      ).data;
      this.projects = response;
    },
    check_data() {
      // if (this.currentData.number == '') {
      // this.alert_message = globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields");
      // this.alert_type = 'warning';
      // this.is_alert = true;
      // return true false;
      // }
    },

    add_records: async function () {
      // if (this.black_list_number.number == '') {
      //   this.alert_message = globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields");
      //   this.alert_type = 'warning';
      //   this.is_alert = true;
      //   return;
      // }
      // if (this.check_data) {
      //   // alert
      //   return;
      // }
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(
            `spv/v1/SmsFilter`,
            { FilterInfo: JSON.stringify(this.currentData) }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `spv/v1/SmsFilter`,
            {
              FilterInfo: JSON.stringify(this.currentData),
            }
          )
        ).data;
      }

      // if (response != null || response != undefined) {
      //   if (response.command == 'UPDATE') {
      //     this.alert_message = `${this.black_list_number.number} ${globalThis._lang("t_hasBeenUpdatedSuccessfully")}`;
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   } else {
      //     this.alert_message = globalThis._lang("t_hasBeenCreatedSuccessfully");
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   }
      // }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`spv/v1/SmsFilter/${this.currentData._id}`)
      ).data;


      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        id: '',
        project: '',
        internal_name: '',
        display_name: '',
        filters: [],
      }
    },
    repeateAgain() {
      this.currentData.filters.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.currentData.filters.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
      })
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      contact: '',
    }

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const isAddNewUserSidebarActive = ref(false)

    const projects = [
      { label: 'Garanti', value: 'garanti' },
      { label: 'Akbank', value: 'akbank' },
      { label: 'Turkcell', value: 'turkcell' },
    ]

    const extensOptions = [
      { label: '123', value: '123' },
      { label: '456', value: '456' },
      { label: '9999', value: '9999' },
      { label: '654', value: '654' },
    ]
    const projectOptions = [
      { label: 'Abc', value: '1' },
      { label: 'Proje2', value: '2' },
      { label: 'Proje3', value: '3' },
      { label: 'Proje4', value: '4' },
    ]
    const profileOptions = [
      { label: 'Admin', value: '1' },
      { label: 'Profil2', value: '2' },
      { label: 'Profil3', value: '3' },
      { label: 'Profil4', value: '4' },
    ]


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      projects,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vs__dropdown-menu {
  max-height: 200px !important;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
