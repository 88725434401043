<template>
  <div>
    <b-row class="mb-1 mx-0" style="overflow-x: auto; white-space: nowrap">
      <!-- <b-button
        style="width: 130px"
        class="ml-1"
        :variant="
          selectedButton != 'sender' ? 'outline-primary' : 'gradient-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'sender'"
      >
        {{ lang("t_smsSending") }}
      </b-button> -->
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'template' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'template'"
      >
        {{ lang("t_pattern") }}
      </b-button>
      <!-- <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'filter' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'filter'"
      >
        {{ lang("t_filter") }}
      </b-button> -->
      <b-button
        style="width: 130px"
        class="float-right ml-auto mr-1 ml-50"
        :variant="
          selectedButton != 'settings' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'settings'"
      >
        {{ lang("t_settings") }}
      </b-button>
    </b-row>

    <b-card>
      <!-- <sms-sender v-if="selectedButton == 'sender'"></sms-sender> -->
      <e-mail-template v-if="selectedButton == 'template'"></e-mail-template>
      <e-mail-filter v-if="selectedButton == 'filter'"></e-mail-filter>
      <e-mail-settings v-if="selectedButton == 'settings'"></e-mail-settings>
      <e-mail-sender v-if="selectedButton == 'sender'"></e-mail-sender>
    </b-card>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
import EMailSender from "./EMailSender/Sender.vue";
import EMailFilter from "./EMailFilter/list/List.vue";
import EMailSettings from "./EMailSettings/list/List.vue";
import EMailTemplate from "./EMailTemplate/list/List.vue";

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      selectedButton: "template",
      optionsButton: [
        { text: globalThis._lang("t_eMailSending"), value: "sender" },
        { text: globalThis._lang("t_eMailTemplate"), value: "template" },
        { text: globalThis._lang("t_eMailFilter"), value: "filter" },
        { text: globalThis._lang("t_eMailSettings"), value: "settings" },
      ],
    };
  },
  components: {
    EMailFilter,
    EMailTemplate,
    EMailSettings,
    EMailSender,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
};
</script>

<style>
</style>
